<template>
  <div class="timeline-container">
    <!-- Overlay -->
    <div class="overlay">
      <!-- Columna izquierda con icono y texto -->
      <div class="icon">
        <img
          src="@/assets/regalos.gif"
          alt="icon-right"
          class="icon-image"
        >
       
        <div class="text">
          Mesa de Regalos
        </div>
      </div>
      <div
        ref="overlay2"
        class="overlay2"
      >
        <div class="icon2">
          <img
            src="@/assets/liverpool.png"
            alt="icon-right"
            class="icon-image"
          >
        </div>
        <div class="text3">
          No. Mesa Regalos  <strong>#51516494</strong>
        </div>
        <a
          href="https://mesaderegalos.liverpool.com.mx/milistaderegalos/51516494"
          class="rainbow rainbow-1"
          target="_blank"
          rel="noopener noreferrer"
        >
          Ir a mesa de Regalos
        </a>
      </div>
      <div
        ref="overlay3"
        class="overlay3"
      >
        <div class="icon2">
          <img
            src="@/assets/sobre.svg"
            alt="icon-right"
            class="icon-image"
          >
        </div>
        <div class="text3">
          "La lluvia de sobres, es la tradición de regalar dinero en efectivo a los novios en un sobre el día del evento"
        </div>
      </div>
    </div>
    <img
        src="@/assets/separador1.png"
        alt="Decoración SVG"
        class="decorative-svg"
      >
  </div>
</template>
  
  
 <script>
import { onMounted, ref, nextTick } from 'vue';

export default {
  name: 'TimelineComponent',
  setup() {
    const overlay2 = ref(null);
    const overlay3 = ref(null);

    onMounted(() => {
      nextTick(() => {
        const observerOptions = {
          root: null,
          threshold: [0.1], // Cambiado para observar cuando al menos el 10% del elemento es visible
        };

        const observerCallback = (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // Aplicar animación solo si el elemento no tiene ya la clase
              if (!entry.target.classList.contains('animate-fadeInUpScale')) {
                entry.target.classList.add('animate-fadeInUpScale');
              }
            } else {
              // Remover la clase para reiniciar la animación cuando el elemento sale del viewport
              if (entry.target.classList.contains('animate-fadeInUpScale')) {
                entry.target.classList.remove('animate-fadeInUpScale');
              }
            }
          });
        };

        const observer = new IntersectionObserver(observerCallback, observerOptions);

        // Observa los elementos overlay2 y overlay3
        if (overlay2.value) {
          observer.observe(overlay2.value);
        }
        if (overlay3.value) {
          observer.observe(overlay3.value);
        }
      });
    });

    return {
      overlay2,
      overlay3,
    };
  },
};


</script>

  
  <style scoped>
/* Contenedor general del timeline */
/* Contenedor general del timeline */
.timeline-container {
  position: relative;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 30px;
  margin: 0;
  background-color: rgb(255, 253, 253);
  z-index: 1;
 
justify-content: center;  /* Centra los elementos de izquierda a derecha */
  align-items: center;  /* Centra los elementos de arriba hacia abajo */

  display: flex;
  flex-direction: column;
  background-size: auto; /* Mantiene el tamaño real de la imagen */
  background-position: center; /* Centra la imagen en el contenedor */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  align-items: center;

  gap: 20px;
  box-sizing: border-box; /* Asegura que el padding no agregue más ancho */
  overflow-x: hidden; /* Evita el desbordamiento horizontal */
}

/* Línea vertical */
.timeline-line {
  width: 2px;
  background-color: #f4f4f4;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
  z-index: -2;
  pointer-events: none;
  height: 100%; /* Ajusta la altura para que cubra el contenedor */
}

/* Ajusta el padding del timeline-container si es necesario */
.timeline-container::after {
  content: "";
  display: block;
  height: 1px; /* Un ajuste mínimo para evitar el recorte del contenido */
  margin-bottom: -1px; /* Compensa el margen para evitar el espacio vacío */
}



/* Columnas para iconos y texto */
.timeline-left,
.timeline-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 300px;
  padding: 10px;
  box-sizing: border-box;
  z-index: 10;
}

/* Icono */
.icon {
  margin-bottom: 20px;
  margin-top: 10px;
  transform: scale(0.9); /* Escala el ícono por un factor de 1.5 */
  height: auto; /* Mantiene las proporciones */
}

/* Icono de imagen */
.icon-image {
  width: 100%;
  max-width: 100px;
  height: auto;
}


.text2 {
  font-size: 16px;
  text-align: center;
  color: rgba(46, 44, 44, 0.9);
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: 'Dancing Script', cursive;
  font-weight: 300;
}

.text3 {
  font-size: 15px;
  text-align: center;
  color: rgba(42, 40, 40, 0.845);
  font-family: 'Josefin Sans', sans-serif;
  margin-top: 0px;
  margin-bottom: 20px;
  font-weight: 350;
}

/* Ajustes de overlay2 */
.overlay2,
.overlay3 { /* Aplica los mismos estilos a overlay3 */
  background-color: rgba(255, 255, 255, 0.8); /* Fondo blanco con opacidad */
  border-radius: 10px; /* Bordes redondeados (opcional) */
  margin-left: 10%; /* Añadir margen izquierdo para equilibrar */
  margin-right: 5%; /* Ajusta el margen derecho */
  padding: 20px; /* Espaciado interno */
  display: flex;
  margin-top: 10px;
  flex-direction: column; /* Disposición en columna */
  justify-content: center; /* Centrar verticalmente el contenido */
  align-items: center; /* Centrar horizontalmente el contenido */
  width: 90%; /* Ancho del overlay como un porcentaje del contenedor padre */
  max-width: 600px; /* Ancho máximo para limitar el tamaño máximo */
  text-align: center; /* Centra el texto */
  margin-left: 10px; /* Añade margen izquierdo si es necesario */
  margin-right: 30px; /* Aumenta el margen derecho para ver el efecto */

  opacity: 0; /* Oculta los elementos inicialmente */
  transform: translateY(30px); /* Posiciona los elementos fuera del viewport verticalmente */
  transition: opacity 0.5s ease-out, transform 0.5s ease-out; /* Suaviza la transición para la visibilidad y el movimiento */
}

/* Estilos para cuando los elementos son visibles */
.overlay2.animate-fadeInUpScale,
.overlay3.animate-fadeInUpScale {
  opacity: 1; /* Los elementos serán visibles */
  transform: translateY(0); /* Los elementos se moverán a su posición original */
}

/* Ajustes para el ícono en overlay2 y overlay3 */
.overlay2 .icon2,
.overlay3 .icon2 { /* Aplica los mismos estilos a los íconos en overlay3 */
  margin-bottom: 5px; /* Espacio entre el ícono y el texto */
  transform: scale(0.7); /* Escala el ícono por un factor de 1.5 */

}

.overlay2 .icon-image,
.overlay3 .icon-image { /* Aplica los mismos estilos a las imágenes en overlay3 */
  width: 100px; /* Ajusta el tamaño del ícono según sea necesario */
  height: auto; /* Mantiene las proporciones */
}

/* Ajustes de overlay */
/* Ajustes de overlay */
.overlay {
  background: linear-gradient(to bottom, #f4f4f4, #ffffff); /* Degradado suave de gris claro a blanco */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra para efecto de elevación */
  padding: 20px; /* Espaciado interno */
  max-width: 800px; /* Ancho máximo para parecer una tarjeta */
  width: 90%; /* Ancho responsivo */
  margin: 20px auto; /* Centrado horizontal */
  margin: 20px auto 20px 20px; /* Reduce el margen izquierdo a 10px */
  flex-direction: column; /* Disposición en columna */
  justify-content: center; /* Centrar verticalmente el contenido */
  align-items: center; /* Centrar horizontalmente el contenido */
  margin-left: 5%; /* Añadir margen izquierdo para equilibrar */
  margin-right: 5%; /* Ajusta el margen derecho */

  z-index: 2; /* Asegúrate de que esté por encima de otros elementos */
}



/* Estilos rainbow */
/* Estilos para que el elemento tenga apariencia de botón */
/* Estilos para que el enlace tenga apariencia de botón */
.rainbow {
  display: inline-block;
  
  font-size: 10px;
  text-align: center;
  color: rgba(222, 221, 221, 0.9);
  font-family: 'Josefin Sans', sans-serif;


  text-transform: uppercase;
  text-decoration: none;
  background-image: linear-gradient(45deg, #3e3f41, #1b1b25, #4d4a43, #7e757e, #504d4f, #191923, #07060e);
  background-size: 400%;
  padding: 12px 24px; /* Espaciado interno del botón */
  border: 2px solid transparent; /* Borde transparente para evitar un borde visible inicialmente */
  border-radius: 5px; /* Esquinas redondeadas */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra sutil para el botón */
  transition: background-position 0.5s ease, transform 0.2s ease; /* Transición suave para el gradiente y el efecto de escala */
  cursor: pointer; /* Cambia el cursor para indicar que es clickeable */
  z-index: 20;
}

/* Efecto de hover para el botón */
.rainbow:hover {
  background-position: 100% 100%;
  transform: scale(1.05); /* Escala ligeramente el botón al pasar el ratón */
}

/* Efecto de foco para accesibilidad */
.rainbow:focus {
  outline: none; /* Elimina el borde por defecto del foco */
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.2); /* Agrega un borde sutil al enfocarse */
}

/* Efecto de animación del gradiente al inicio */
@keyframes gradientAnimation {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 100%;
  }
}

.rainbow {
  animation: gradientAnimation 5s linear infinite; /* Aplica la animación del gradiente al botón */
}






/* Animación de desplazamiento hacia arriba */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Clase de animación aplicada a los elementos cuando se vuelven visibles */
.animate-fadeInUp {
  animation: fadeInUp 1s ease-out forwards;
}



/* Animación de desplazamiento hacia arriba y escala */
@keyframes fadeInUpScale {
  from {
    opacity: 0;
    transform: translateY(30px) scale(0.8); /* Escala inicial más pequeña */
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1); /* Escala original */
  }
}

/* Clase de animación aplicada a los elementos cuando se vuelven visibles */
.animate-fadeInUpScale {
  animation: fadeInUpScale 0.5s ease-out forwards;
}

/* Estilo del texto */
.text{
  text-align: center;

  font-size: 26px;
  letter-spacing: 5px; /* Espaciado entre letras */
  font-family: 'FeelingPassionate';
z-index:10;
  font-weight: 700;
  margin: 0 30px;
  background: linear-gradient(270deg, #141415,  #e6e1e1, #0e0e0e);
  background-size: 200% 100%;
  background-clip: text;
  color: transparent;
  line-height: 2.2; /* Ajusta este valor según sea necesario */
  animation: animateText 5s linear infinite;
}
@font-face {
  font-family: 'FeelingPassionate';
  src: url('@/assets/fonts/passionate.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block; /* Espera a que la fuente esté cargada antes de mostrarla */
}
@keyframes animateText {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.decorative-svg {
  width: 100%;
  max-width: 50%; /* Cambia esto para hacerla más pequeña */
  height: auto;
  display: block;
  margin-top: 20px;
  margin-bottom:-20px;
}
  </style>
  
