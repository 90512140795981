<template>
  <div
    v-if="showLogo"
    id="InicioLogo"
    class="tooltip-container"
  >
    <!-- Imagen superior -->

    
    <!-- Logo centrado -->
    <transition
      name="fade"
      @before-leave="beforeLeaveLogo"
      @leave="leaveLogo"
    >
      <img
        v-if="!transitioning"
        :src="logo"
        alt="Logo"
        class="logo"
      >
    </transition>
    
    <!-- Imagen inferior -->
    
    
    <!-- Tooltip -->
    <span
      class="tooltip-text"
      :class="{ 'show-tooltip': showTooltip }"
    >{{ tooltipText }}</span>
  </div>
</template>

<script>
import logoinicial from '../assets/sello.png';
import topImg from '../assets/sobre1.png'; // Ruta de la primera imagen
import bottomImg from '../assets/sobre2.png'; // Ruta de la tercera imagen

export default {
  name: 'InicioLogo',
  data() {
    return {
      logo: logoinicial,
      bottomImage: bottomImg,
       topImage: topImg,
      tooltipText: '¡Pulsa aquí!',
      showTooltip: false,
      showLogo: true, // Controlar la visibilidad del logo
      tooltipTimeout: null,
      transitioning: false,
    };
  },
  mounted() {
    // Mostrar el tooltip automáticamente después de 4 segundos
    this.tooltipTimeout = setTimeout(() => {
      this.showTooltip = true;
    }, 4000); // 4000 milisegundos = 4 segundos

    // Añadir un listener global para clics en el documento
    document.addEventListener('click', this.handleClickAnywhere);
  },
  beforeUnmount() {
    if (this.tooltipTimeout) {
      clearTimeout(this.tooltipTimeout);
    }
    document.removeEventListener('click', this.handleClickAnywhere);
  },
  methods: {
  hideTooltip() {
    this.showTooltip = false;
    this.startTransition();
  },
  handleClickAnywhere() {
    this.hideTooltip();
  },
  startTransition() {
    this.transitioning = true;
    setTimeout(() => {
      this.showLogo = false;
    }, 1000);
  },
  beforeLeaveBottomImage(el) {
    el.style.transition = 'transform 1s ease-in-out';
  },
  leaveBottomImage(el) {
    el.style.transform = 'translateX(-100%)'; // Mueve la imagen hacia la izquierda
  },
  beforeLeaveTopImage(el) {
    el.style.transition = 'transform 1s ease-in-out';
  },
  leaveTopImage(el) {
    el.style.transform = 'translateX(100%)'; // Mueve la imagen hacia la derecha
  },
  beforeLeaveLogo(el) {
    el.style.transition = 'opacity 1s ease-in-out';
  },
  leaveLogo(el) {
    el.style.opacity = 0;
  },
}

};
</script>

<style scoped>
#InicioLogo {
  position: fixed; /* Cambia a fixed para que siempre esté visible */
  top: 0; /* Empieza desde la parte superior de la pantalla */
  left: 0; /* Empieza desde el borde izquierdo */
  width: 100vw; /* Ocupa todo el ancho de la pantalla */
  height: 100vh; /* Ocupa todo el alto de la pantalla */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; /* Centra horizontal y verticalmente */
  box-sizing: border-box;
  background-color:#f1f1f1;
  z-index: 20; /* Asegura que el contenedor esté encima de otros elementos */
  margin: 0; /* Elimina el margen para ajustar el diseño */
  border: none; /* Elimina cualquier borde si no es necesario */
  overflow: hidden; /* Evita el desbordamiento */
}

body {
  overflow: hidden; /* Oculta la barra de desplazamiento horizontal y vertical */
}

.top-image {
  width: 100vw; /* Ocupa todo el ancho de la pantalla */
  height: 100vh; /* Ocupa el 50% del alto de la pantalla */
  z-index: 1; /* Asegura que el tooltip esté encima de otros elementos */
  margin: 0; /* Elimina el margen para ajustarse al diseño */
  position: absolute;
  left: 0;
}

.logo {
  max-width: 180px; /* Ajusta el tamaño máximo del logo según sea necesario */
  z-index: 20; /* Asegura que el logo esté por encima de las imágenes */
  margin: 0; /* Elimina el margen para ajustarse al diseño */
  animation: scaleAnimation 1s ease-in-out infinite alternate both; /* Reduce el tiempo de la animación a 1s */
  cursor: pointer;
  
}

.bottom-image {
  width: 60vw; /* Ocupa todo el ancho de la pantalla */
  height: 100vh; /* Ocupa el 50% del alto de la pantalla */
  float: left;
  position: absolute;
  right: 0%;
  z-index: 15; /* Asegura que el tooltip esté encima de otros elementos */
}

.tooltip-text {
  visibility: hidden;
  width: 120px; /* Ajusta el ancho del tooltip para hacerlo más pequeño */
  background-color: rgba(0, 0, 0, 0.10); /* Negro con 10% de opacidad */
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px; /* Ajusta el padding para que el tooltip sea más pequeño */
  position: absolute;
  top: calc(50% - 70px); /* Ajusta la posición vertical del tooltip si es necesario */
  left: 50%;
  transform: translateX(-50%); /* Centra el tooltip horizontalmente */
  opacity: 0;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  z-index: 30; /* Asegura que el tooltip esté encima de otros elementos */
}

.show-tooltip {
  visibility: visible;
  opacity: 1;
}

/* Transiciones */
.fade-up-enter-active, .fade-up-leave-active {
  transition: transform 1s ease-in-out;
}
.fade-up-enter, .fade-up-leave-to {
  transform: translateX(-100%);
}

.fade-down-enter-active, .fade-down-leave-active {
  transition: transform 1s ease-in-out;
}
.fade-down-enter, .fade-down-leave-to {
  transform: translateX(100%);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s ease-in-out;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

@keyframes scaleAnimation {
  0% {
    transform: scale(0.9);
    opacity: 0.8;
  }
  100% {
    transform: scale(1.2);
    opacity: 1;
  }
}
</style>  
