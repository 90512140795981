<template>
  <div
    v-if="(paquete === 2 || paquete === 3 || paquete === 4) && welcomeText"
    ref="bienvenidaRef"
    class="bienvenida-overlay"
  >
    <div class="overlay-content">
      <div class="franja-superior">
        <span class="titulo-pase">APRECIABLE:</span>
      </div>
      <div class="texto-titulo">
        <br>
      </div>
      <div class="welcome-text">
        {{ welcomeText }}
      </div>
      <div
        v-if="(paquete === 3 || paquete === 4) && numpase"
        class="pases-container"
      >
        <div class="texto-titulo">
          No. Pases Asignados:
        </div>
        <span> {{ numpase }} </span>
      </div>
      <div
        v-if="paquete === 4 && mesaText && numpase"
        class="mesa-container"
      >
        <div class="texto-titulo">
          Mesa:
        </div>
        <span> {{ mesaText }}</span>
      </div>
    </div>
    <!-- Tooltip -->
    <div
      v-if="showTooltip"
      :class="['tooltip', { show: showTooltip }]"
      @click="hideTooltip"
    >
      ¡Recuerda que es muy importante confirmar asistencia!
    </div>
  </div>
</template>

<script>
import { onMounted, ref, nextTick, computed, onBeforeUnmount } from 'vue';
import axios from 'axios';
import baseUrl from '../api';  // Asegúrate de importar la URL base


export default {
  name: 'InvitadoEvento',
  props: {
    idInvitado: {
      type: Number,
      required: true,
      validator(value) {
        return !isNaN(value) && value > 0;
      }
    },
    nombre: {
      type: String,
      default: '',
    },
    idFestejado: {
      type: Number,
      default: null,
    }
  },
  setup(props) {
    const bienvenidaRef = ref(null);
    const welcomeText = ref('');
    const mesaText = ref('');
    const numpase = ref(null);
    const paquete = ref(null);
    const showTooltip = ref(false);
    let tooltipTimeout;

    const isValidIdInvitado = computed(() => !isNaN(props.idInvitado) && props.idInvitado > 0);

    const fetchData = async () => {
      if (isValidIdInvitado.value) {
        const idInvitado = parseInt(props.idInvitado, 10);
        try {
          const url = `${baseUrl}/${props.nombre ? `${props.nombre}/` : ''}${props.idFestejado ? `${props.idFestejado}/` : ''}${idInvitado ? `${idInvitado}` : ''}`;

          console.log('URL:', url);

          const responseInvitado = await axios.get(url);
          console.log('Respuesta del servidor (invitado):', responseInvitado.data);
          welcomeText.value = responseInvitado.data.nombre_mostrar;
          mesaText.value = responseInvitado.data.num_mesa;
          numpase.value = responseInvitado.data.num_pase;

          const paqueteUrl = `${baseUrl}/${props.nombre ? `${props.nombre}/` : ''}${props.idFestejado ? `${props.idFestejado}/` : ''}${idInvitado ? `${idInvitado}` : ''}`;
          console.log('URL del paquete:', paqueteUrl);
          console.log('Base URL:', baseUrl);

          const response = await axios.get(paqueteUrl);
          console.log('Respuesta del servidor:', response.data);
          paquete.value = response.data.paquete;

          console.log('Paquete:', paquete.value);
          console.log('Número de pases:', numpase.value);
          console.log('Mesa asignada:', mesaText.value);
        } catch (error) {
          console.error('Error al obtener los datos:', error);
          console.error('Error al obtener los datos:', error.response ? error.response.data : error.message);
        }
      }
    };

    onMounted(async () => {
      if (isValidIdInvitado.value) {
        await fetchData();
        await nextTick();
        if (bienvenidaRef.value) {
          const element = bienvenidaRef.value;

          // Inicialmente ocultar el tooltip
          showTooltip.value = false;

          const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: [0.1]
          };

          const observerCallback = (entries) => {
            entries.forEach(entry => {
              console.log('Observando elemento:', entry.target);
              if (entry.isIntersecting) {
                // Mostrar tooltip después de 2 segundos
                tooltipTimeout = setTimeout(() => {
                  showTooltip.value = true; // Mostrar tooltip al entrar en el viewport
                }, 3000);
              } else {
                showTooltip.value = false; // Ocultar tooltip al salir del viewport
                clearTimeout(tooltipTimeout); // Limpiar el timeout si se sale del viewport
              }
            });
          };

          const observer = new IntersectionObserver(observerCallback, observerOptions);
          observer.observe(element);
        }
      }
    });

    // Limpiar timeout al destruir el componente
    onBeforeUnmount(() => {
      clearTimeout(tooltipTimeout);
    });

    return { 
      bienvenidaRef, 
      welcomeText, 
      mesaText, 
      numpase, 
      paquete, 
      isValidIdInvitado, 
      showTooltip 
    };
  }
};
</script>

<style scoped>
/* Evitar el desbordamiento horizontal */
body {
  overflow-x: hidden;
}



/* Ajustes para la imagen de fondo y el contenedor */
.bienvenida-overlay {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 45vh; /* Tamaño ajustable */
  z-index: 10;
  background-size: cover;
  background-position: center;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Estilo para el texto de título */
.texto-titulo {
  font-size: 1rem; /* Tamaño ajustado */
  color: rgb(74, 70, 70);
  text-shadow: 2px 2px 4px rgba(109, 104, 104, 0.7);
  margin-bottom: 5px;
  font-family: 'Karla', sans-serif;
  letter-spacing: 1px; /* Espaciado sutil */

}

/* Estilo para el texto de bienvenida */
.overlay-content {
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15); /* Sombras más suaves */

  background-color: rgba(255, 255, 255, 0.8); /* Fondo con algo de transparencia */
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Sombra más suave */
  padding: 20px;
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
  text-align: center;
  margin-top: -50px;
}
.overlay-content:hover {
  transform: scale(1.05); /* Efecto de ampliación */

}
.welcome-text {
  font-size: 2rem;
  font-weight: bold;
  font-family: 'Dancing script', sans-serif;
  color: #36353ad4;
  margin-top: 5px;
  margin-bottom: 15px;
  letter-spacing: 1px; /* Sutil espaciado de letras */
  text-align: center; /* Asegura que el texto esté centrado */
  border-radius: 8px;
  background-size: 400% 400%;
  animation: gradientAnimation 5s ease infinite;
  transition: transform 0.3s ease;
  animation: pulse 2s infinite;
}

/* Franja superior de la tarjeta */
.franja-superior {
  background: linear-gradient(135deg, #342434, #000); /* Degradado sutil de gris a negro */
  font-size: 18px; /* Ajustar tamaño del texto */
  font-family: 'Jost', sans-serif;
  letter-spacing: 2px; /* Añade espaciado entre los caracteres */

  font-weight: 400;
  color: white;
  padding: 10px;
  width: 100%; /* Más ancha que el contenedor */
  padding: 5px 10px; /* Reduce el padding vertical */
margin-top:-20px;
  text-align: center;
  font-size: 1rem;
  padding: 10px; /* Aumenta el padding para que la franja sea más alta */

  font-weight: bold;
  border-radius: 15px 15px 0 0;
}

/* Animación de hover para el texto */
.welcome-text:hover {
  transform: scale(1.05); /* Efecto de zoom al pasar el mouse */
}

/* Animación de degradado */
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Hacer que el overlay se vea bien en pantallas más pequeñas */
@media (max-width: 768px) {
  .overlay-content {
    width: 80%;
    padding: 10px 0px;
  }

  .welcome-text {
    font-size: 1.6rem;
  }

}

/* Animación de pulso */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

/* Estilo para el contenedor de pases */
.pases-container {
  text-align: center; /* Centrar el contenido */
  margin-top: 20px; /* Espaciado superior */
}
/* Estilo para el contenedor de pases */
.pases-container {
  display: flex; /* Hacer que el contenedor sea flexible */
  justify-content: center; /* Centrar el contenido horizontalmente */
  align-items: center; /* Centrar el contenido verticalmente */
  margin-top: 20px; /* Espaciado superior */
}

/* Estilo para el número de pases */
.pases-container span {
  font-size: 1.5rem; /* Tamaño ajustado */
 
  font-weight: bold;
  font-family: 'Josefin Sans', sans-serif;

  font-weight: 350;

  font-weight: 300; /* Peso de la fuente */
  border-radius: 8px; /* Bordes redondeados */
  color: black; /* Texto negro para buen contraste */
  margin-left: 10px; /* Espaciado entre el título y el número */
}

/* Estilo para el contenedor de mesa */
.mesa-container {
  display: flex; /* Hacer que el contenedor sea flexible */
  justify-content: center; /* Centrar el contenido horizontalmente */
  align-items: center; /* Centrar el contenido verticalmente */
  margin-top: 20px; /* Espaciado superior */
}

/* Estilo para el número de mesa */
.mesa-container span {
  font-size: 1.5rem; /* Tamaño ajustado */
 
 font-weight: bold;
 font-family: 'Josefin Sans', sans-serif;

 font-weight: 350;

 font-weight: 300; /* Peso de la fuente */
 border-radius: 8px; /* Bordes redondeados */
 color: black; /* Texto negro para buen contraste */
 margin-left: 10px; /* Espaciado entre el título y el número */
}


.tooltip {
  position: absolute;
  top: 75%; /* Centrado vertical */
  left: 15%; /* Centrado horizontal */
  right:15%; /* Centrado horizontal */

  background-color: rgb(46, 44, 44);
  color: #ffffff;
  padding: 10px 10px;
  border-radius: 5px;
  font-size: 14px;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.3s ease;
  text-align: center; /* Centrar el texto */
}

.tooltip.show {
  opacity: 1;
  animation: shake 0.5s ease; /* Animación de movimiento */

}
@keyframes shake {
  0% { transform: translateX(-5px); }
  25% { transform: translateX(5px); }
  50% { transform: translateX(-5px); }
  75% { transform: translateX(5px); }
  100% { transform: translateX(0); }
}
</style>
