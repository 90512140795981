<template>
  <div>
    <!-- Contenedor Bienvenida -->
     
    <div
      class="bienvenida-container"
    >
      <img
        src="@/assets/separador1.png"
        alt="Decoración SVG"
        class="decorative-svg"
      >
      <div ref="bienvenidaRef">
        <br> <br><br> ¡Que nos acompañes es lo mas importante! <br> Y si está en su disposición realizar una muestra de cariño estaremos muy agradecidos.<br>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, nextTick } from 'vue';
import 'animate.css'; // Asegúrate de importar animate.css
import separadorImg from '@/assets/separador1.png';

export default {
  name: 'BienvenidaPage',
  setup() {
    const bienvenidaRef = ref(null);
    const animationDone = ref(false); // Para controlar si la animación ya se ha hecho

    onMounted(async () => {
      await nextTick(); // Asegurarte de que el DOM esté completamente actualizado

      if (bienvenidaRef.value) {
        const element = bienvenidaRef.value;

        // Configuración del IntersectionObserver
        const observerOptions = {
          root: null, // Usar el viewport como raíz
          rootMargin: '0px', // Ajustar si es necesario
          threshold: [0.1] // Activar cuando el 10% del elemento sea visible
        };

        const observerCallback = (entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              if (!animationDone.value) {
                // Si el elemento es visible y la animación no ha sido realizada
                element.classList.add('animate__animated', 'animate__fadeInUp');
                element.addEventListener('animationend', () => {
                  element.classList.remove('animate__animated', 'animate__fadeInUp');
                  element.style.opacity = '1'; // Asegúrate de que el elemento se mantenga visible
                }, { once: true });

                // Marca la animación como realizada
                animationDone.value = true;
              }
            } else {
              // Si el elemento se vuelve invisible, resetea la animación
              element.style.opacity = '0'; // Ocultar el elemento cuando no es visible
              // Marca la animación como no realizada
              animationDone.value = false;
            }
          });
        };

        // Crear un nuevo IntersectionObserver
        const observer = new IntersectionObserver(observerCallback, observerOptions);
        observer.observe(element);
      }
    });

    return { bienvenidaRef, separadorImg };
  }
};
</script>

<style scoped>
/* Contenedor Bienvenida */
.bienvenida-container {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto; /* Centra el contenedor horizontalmente */
  padding: 20px;
  text-align: center;
  transform: translateY(0);
  z-index: 1;
  font-size: 18px;
  font-weight: 400;
  font-family: 'Josefin Sans', sans-serif;
  color: rgba(97, 82, 94, 0.669);
  white-space: pre-line; /* Respeta los saltos de línea */
  
  /* Flexbox para centrar el contenido */
  display: flex;
  flex-direction: column; /* Apila los elementos verticalmente */
  align-items: center; /* Centra horizontalmente */
}


/* Aplicar animación y mantener el estado final */
.animate__animated {
  animation-fill-mode: both; /* Mantiene el estado final de la animación */
}
.decorative-svg {
  width: 100%;
  max-width: 50%; /* Cambia esto para hacerla más pequeña */
  height: auto;
  display: block;
  margin-top: 20px;
  margin-bottom:-20px;
}


</style>
