<template>
  <section
    id="itinerario"
    class="itinerario-section"
  >
    <!-- Barra de Progreso Vertical Solo en la Sección -->
    <div
      class="progress-bar-vertical"
      :style="{ height: progressHeight }"
    />

    <div class="container">
      <h2 class="title">
        Itinerario
      </h2>

      <div class="timeline">
        <div
          v-for="evento in eventos"
          :key="evento.nombre"
          class="event d-flex align-items-center"
          :data-nombre="evento.nombre"
        >
          <div
            class="icon-container"
            :style="getEventStyle(evento)"
          >
            <img
              :src="evento.icono"
              width="60"
              alt=""
              class="icono"
              :style="getIconStyle(evento)"
            >
            <div class="line" />
          </div>
          <div class="event-text">
            <h3>{{ evento.nombre }}</h3>
            <p>{{ evento.hora }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
export default {
  name: 'ItinerarioCmd',
  data() {
    return {
      eventos: [
        { nombre: 'Ceremonia Religiosa', hora: '17:00 hrs', icono: require('@/assets/iglesia-itinerario1.gif'), visible: false },
        { nombre: 'Ceremonia Civil', hora: '18:30 hrs', icono: require('@/assets/ceremonia_time.png'), visible: false },
        { nombre: 'Recepción', hora: '21:00 hrs', icono: require('@/assets/recepcion_time.png'), visible: false },
        { nombre: 'Entrada Novios', hora: '21:10 hrs', icono: require('@/assets/entradanovios_time.png'), visible: false },
        { nombre: 'Cena', hora: '21:30 hrs', icono: require('@/assets/cena_time.png'), visible: false },
        { nombre: 'Fiesta', hora: '22:00 hrs - 2:00 hrs', icono: require('@/assets/fiesta_time.png'), visible: false },
      ],
      progressHeight: '0%' // Para la barra de progreso vertical
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.marcarComoVisible(entry);
        }
      });
    }, { threshold: 0.5 });

    this.$nextTick(() => {
      const elementos = document.querySelectorAll('.event');
      elementos.forEach(el => observer.observe(el));
    });
  },
  methods: {
    handleScroll() {
      const itinerarioSection = document.getElementById('itinerario');
      const totalHeight = itinerarioSection.scrollHeight - itinerarioSection.clientHeight;
      const scrollPosition = itinerarioSection.scrollTop;

      this.progressHeight = `${(scrollPosition / totalHeight) * 100}%`; // Llenado de la barra
    },
    getEventStyle(evento) {
  return evento.visible
    ? { backgroundColor: '#333333', borderColor: '#333333' }
    : { backgroundColor: '#f3eadc', borderColor: '#a79683' };
},
    getIconStyle(evento) {
      return evento.visible
        ? { filter: 'brightness(0.6)' }
        : {};
    },
    marcarComoVisible(entry) {
      const evento = this.eventos.find(evento => evento.nombre === entry.target.dataset.nombre);
      if (evento && !evento.visible) {
        evento.visible = true;
      }
    },
  },
};
</script>

<style scoped>
.itinerario-section {
  padding: 50px;
  position: relative;
  background: linear-gradient(to bottom, #f4f4f4, #ffffff); /* Degradado suave de gris claro a blanco */
  height: 140vh; /* Establece la altura de la sección para que ocupe toda la pantalla */
  overflow-y: auto; /* Permite el scroll solo dentro de esta sección */
}

.progress-bar-vertical {
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  background-color: #1f1f1e;
  transition: height 0.25s ease-out;
  height: 0;
  max-height: 100%;
}

.container {
  margin-left: 0; /* Sin espacio extra, alineado a la izquierda */
}

.title {
  font-size: 30px;
  color: #333;
  font-family: 'Great Vibes', cursive;
  letter-spacing: 5px;
  text-align: left;
}

.timeline {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.event {
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.icon-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  background-color: #f3eadc;
  border-radius: 50%;
  border: 4px solid #a79683;
  margin-right: 20px;
  transition: all 0.3s ease-in-out;
}

.icon-container img {
  border-radius: 40%;
}

.icon-container .line {
  position: absolute;
  top: 100%;
  width: 2px;
  height: 20px;
  background-color: #a79683;
}

.event-text h3 {
  font-size: 18px;
  color: #333;
  text-align: left;

  font-family: 'Jost', sans-serif;
}

.event-text p {
  color: #777;
  font-size: 14px;
  text-align: left;

}

.event-text {
  display: flex;
  flex-direction: column;
  text-align: left;

}
</style>
