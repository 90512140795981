<template>
  <div class="collage-carousel">
    <div class="collage-grid">
      <div
        v-for="(image, index) in images"
        :key="index"
        ref="collageItems"
        class="collage-item" 
        :class="{'visible': imageVisible[index]}"
        @click="enlargeImage(index)"
      >
        <img
          :src="image"
          :alt="'Image ' + (index + 1)"
          class="collage-image"
        >
      </div>
    </div>

    <!-- Modal para mostrar la imagen ampliada -->
    <div
      v-if="isImageEnlarged"
      class="image-modal"
      @click="closeModal"
    >
      <img
        :src="images[currentIndex]"
        class="enlarged-image"
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentIndex: 0,
      isImageEnlarged: false,
      images: [
        require('@/assets/6cc.jpg'),
        require('@/assets/6c.jpg'),
        require('@/assets/5c.jpg'),
        require('@/assets/4c.jpg'),


     

      ],
      imageVisible: Array(4).fill(false), // Ajusta la cantidad según el número de imágenes
    };
  },
  mounted() {
    this.initObserver();
  },
  methods: {
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
    },
    prevSlide() {
      this.currentIndex = (this.currentIndex - 1 + this.images.length) % this.images.length;
    },
    enlargeImage(index) {
      this.currentIndex = index;
      this.isImageEnlarged = true;
    },
    closeModal() {
      this.isImageEnlarged = false;
    },
    // Inicia el IntersectionObserver para detectar cuando las imágenes son visibles
    initObserver() {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,  // Cuando al menos 50% de la imagen sea visible
      };

      const observer = new IntersectionObserver(this.handleIntersection, options);
      this.$refs.collageItems.forEach(item => observer.observe(item));
    },
    // Maneja la intersección de las imágenes con el viewport
    handleIntersection(entries) {
      entries.forEach(entry => {
        const index = this.$refs.collageItems.indexOf(entry.target);

        if (entry.isIntersecting) {
          this.imageVisible[index] = true; // Marca la imagen como visible
          this.resetImageAnimation(index); // Resetea la animación al entrar en el viewport
        } else {
          this.imageVisible[index] = false; // Marca la imagen como no visible
        }
      });
    },
    // Resetea la animación de la imagen al hacerla visible nuevamente
    resetImageAnimation(index) {
      const collageItem = this.$refs.collageItems[index];
      
      // Eliminar la clase de animación si está presente
      collageItem.classList.remove('animate-in');
      
      // Forzar el reflujo para reiniciar la animación
      void collageItem.offsetHeight; // Forzar el reflujo

      // Usar requestAnimationFrame para asegurarnos de que la animación se reinicie correctamente
      requestAnimationFrame(() => {
        collageItem.classList.add('animate-in'); // Agregar la clase de animación
      });
    }
  },
};
</script>


<style scoped>
.collage-carousel {
  position: relative;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  overflow: hidden;
  margin-top: 5px;
}

.collage-grid {
  display: block; /* Cambiado a block para apilar las imágenes en una columna */
  width: 100%;
}

.collage-item {
  position: relative;
  width: 100%; /* Ocupa todo el ancho disponible */
  height: auto; /* Ajusta la altura de las imágenes según lo que necesites */
  overflow: hidden;
  margin-bottom: 20px; /* Espacio entre las imágenes */
  cursor: pointer;
  opacity: 0; /* Inicia invisible */
  transform: translateY(30px); /* Inicia desplazada hacia abajo */
  transition: opacity 1s ease-out, transform 1s cubic-bezier(0.68, -0.55, 0.27, 1.55); /* Transición con efecto rebote */
}

.collage-item.visible {
  opacity: 1; /* Se hace visible */
  transform: translateY(0) scale(1); /* Vuelve a su posición original con efecto de rebote */
}

.collage-item.animate-in {
  opacity: 1; /* Se hace visible con animación */
  transform: translateY(0) scale(1); /* Vuelve a su posición original con animación */
}

.collage-image {
  width: 100%;
  height: 100%;
  object-fit: contain; /* La imagen se adapta sin recortarse */
  border-radius: 5px;
}

.enlarged-image {
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
  border-radius: 10px;
}
</style>
